import { render, staticRenderFns } from "./ExpertsInfo.vue?vue&type=template&id=3144a64f&scoped=true"
import script from "./ExpertsInfo.vue?vue&type=script&lang=js"
export * from "./ExpertsInfo.vue?vue&type=script&lang=js"
import style0 from "./ExpertsInfo.vue?vue&type=style&index=0&id=3144a64f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3144a64f",
  null
  
)

export default component.exports