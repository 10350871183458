<template>
  <div class="subs-info">
    <!--子账号管理-->
    <div style="display: flex; justify-content: space-between; margin: 20px 0 20px 10px">
      <div style="font-size: 22px; font-weight: 600;">子账号所属部门</div>
    </div>

    <div style="margin-bottom: 20px; border-bottom: 3px solid #F5F5F5" class="tabs">
        <div style="height: 460px; overflow-y: scroll" class="subsBox">
          <div v-for="(item, index) in sectionList" class="hand op" :key="index" @click="$emit('selectSection', item)">
            <div class="menu-title" style="font-size: 16px">
              <img style="width: 18px; height: 18px; display: inline-block; vertical-align: middle; margin: -3px 12px 0 0" src="../../../assets/imgs/circle/groupIcon.png">
              {{item.groupName}}
              <!--                                <i style="margin-left: 10px; font-weight: 600" class="el-icon-caret-bottom hand op"></i>-->
              <span @click="selectSections(item)" class="hand op" style="color: #2970FF; float: right">+ 添加成员</span>
            </div>
          </div>
        </div>
    </div>

    <div class="showNote">
      <el-dialog title="编辑子账号信息" :visible.sync="showAddAccount" width="30%" center>
        <el-divider></el-divider>
        <div style="padding: 0 20px 20px 20px">
          <el-form :model="accountInfo" :rules="accountRules" ref="accountForm">
            <div style="margin-bottom: 30px">
              <div class="form-title">
                子账号类型
              </div>
              <el-select v-model="accountInfo.isAdmin" placeholder="请选择">
                <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div style="margin-bottom: 16px">
              <div class="form-title">
                子账号手机号
              </div>
              <el-form-item prop="phone">
                <el-input type="textarea"
                          placeholder="用于激活绑定子账号的手机号码"
                          resize="none"
                          :rows="1"
                          v-model="accountInfo.phone"
                          style="margin-bottom: 16px">
                </el-input>
              </el-form-item>
            </div>
            <div style="margin-bottom: 16px">
              <div class="form-title">
                子账号登录名
              </div>
              <el-form-item prop="account">
                <el-input resize="none"
                          placeholder="字母或数字的组合"
                          :rows="1"
                          v-model="accountInfo.account"
                          style="margin-bottom: 16px">
                  <template slot="prepend">
                    <el-button style="width: 100%;height: 30px;border: 0;background: #F5F7FA" :disabled="disabled">{{organAccountInfo.accoun}}</el-button>
                  </template>
                </el-input>
              </el-form-item>
            </div>
            <div style="margin-bottom: 16px">
              <div class="form-title">
                子账号密码
              </div>
              <el-form-item prop="pwd">
                <el-input type="textarea"
                          placeholder="密码至少为8位字母和数字的组合"
                          resize="none"
                          :rows="1"
                          v-model="accountInfo.pwd"
                          style="margin-bottom: 16px">
                </el-input>
              </el-form-item>
            </div>
            <div style="margin-bottom: 16px">
              <div class="form-title">
                子账号备注
              </div>
              <el-form-item prop="remarks">
                <el-input type="textarea"
                          resize="none"
                          placeholder="子账号分配对象姓名，职务，分管事务等"
                          :rows="1"
                          v-model="accountInfo.remarks"
                          style="margin-bottom: 16px">
                </el-input>
              </el-form-item>
            </div>
          </el-form>
          <el-divider></el-divider>
          <div style="display: flex; justify-content: end;">
            <div>
              <el-button class="cancel-btn" @click="cancelAccount">取消</el-button>
              <el-button class="save-btn" @click="ActivateAccount">激活</el-button>
            </div>
          </div>
        </div>
      </el-dialog>

      <el-dialog :visible.sync="showNote" width="560px" center>
        <div style="font-size: 18px; font-weight: 600; text-align: center; margin-bottom: 20px">
          <div>
            确认激活该机构子账号吗？
          </div>
          <div>
            为该子账号绑定手机号可成功激活
          </div>
        </div>

        <div style="padding: 20px">
          <div style="display: flex; margin-bottom: 20px">
            <div style="font-size: 18px; margin-right: 20px; margin-top: 4px"><span style="color:#FF0000">*</span>手机号</div>
            <div>
              <el-input name="phone" v-model="accountInfo.phone" :disabled="true" style="width: 100%">
                <template slot="prepend">+86</template>
              </el-input>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 20px">
            <div style="font-size: 18px; margin-right: 20px; margin-top: 4px"><span style="color:#FF0000">*</span>验证码</div>
            <div>
              <el-input name="code" v-model="accountInfo.code"  placeholder="请输入短信验证码" >
                <template slot="append">
                  <el-button style="width: 100%;height: 30px;border: 0;background: #F5F7FA" @click="getCode" :disabled="disabled">{{ codeString }}</el-button>
                </template>
              </el-input>
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: end; padding: 20px">
          <div>
            <el-button class="cancel-btn" @click="showNote = false">取消</el-button>
            <el-button class="save-btn" @click="DefiniteActivation">确定</el-button>
          </div>
        </div>
      </el-dialog>

      <el-dialog title="创建部门" :visible.sync="showAddSection" width="30%" center>
        <el-divider></el-divider>
        <div style="padding: 0 20px 20px 20px">
          <el-form :model="sectionInfo" :rules="sectionRules" ref="sectionForm">
            <div style="margin-bottom: 16px">
              <div class="form-title">
                部门名称
              </div>
              <el-form-item prop="name">
                <el-input resize="none"
                          v-model="sectionInfo.name"
                          style="margin-bottom: 16px">
                </el-input>
              </el-form-item>
            </div>

            <div style="margin-bottom: 16px">
              <div class="form-title">
                部门基本信息
              </div>
              <el-form-item prop="content">
                <el-input type="textarea"
                          resize="none"
                          :rows="5"
                          v-model="sectionInfo.content"
                          style="margin-bottom: 16px">
                </el-input>
              </el-form-item>
            </div>
          </el-form>
          <div style="display: flex; justify-content: center;">
            <div>
              <el-button type="primary" class="save-btn" @click="addSection">创建</el-button>
            </div>
          </div>
        </div>
      </el-dialog>

      <el-dialog title="编辑部门" :visible.sync="showEditSection" width="30%" center>
        <el-divider></el-divider>
        <div style="padding: 0 20px 20px 20px">
          <el-form :model="sectionInfo" :rules="sectionRules" ref="sectionForm">
            <div style="margin-bottom: 16px">
              <div class="form-title">
                部门名称
              </div>
              <el-form-item prop="name">
                <el-input resize="none"
                          v-model="sectionInfo.name"
                          style="margin-bottom: 16px">
                </el-input>
              </el-form-item>
            </div>

            <div style="margin-bottom: 16px">
              <div class="form-title">
                部门基本信息
              </div>
              <el-form-item prop="content">
                <el-input type="textarea"
                          resize="none"
                          :rows="5"
                          v-model="sectionInfo.content"
                          style="margin-bottom: 16px">
                </el-input>
              </el-form-item>
            </div>
          </el-form>
          <div style="display: flex; justify-content: center;">
            <div>
              <el-button type="primary" class="save-btn" @click="editSection">确认</el-button>
            </div>
          </div>
        </div>
      </el-dialog>

      <el-dialog title="添加子账号成员" :visible.sync="showSubs" width="340px" center>
        <div style="border-bottom: 1px solid #CECFD0; margin: 10px 0 0 0"></div>
        <div class="menu-title" style="font-size: 16px">
          <span style="color: #999999; margin: 0 30px">添加到</span>
          <img style="width: 18px; height: 18px; display: inline-block; vertical-align: middle; margin: -3px 4px 0 0" src="../../../assets/imgs/circle/groupIcon.png">
          {{selectSection.groupName}}
        </div>
        <div>
          <div style="max-height: 600px; overflow-y: scroll">
            <div style="height: 40px; line-height: 40px; margin-left: 20px" v-for="(item, index) in otherSubsList" :key="index">
              <el-radio-group v-model="selectSubList">
                <el-radio :label="item.uuid">
                  <i class="el-icon-user" style="margin-right: 2px"></i>
                  <span>{{item.nickName}} （{{item.remarks }}）</span>
                </el-radio>
              </el-radio-group>
            </div>
          </div>
          <div style="border-bottom: 1px solid #CECFD0; margin: 10px 0"></div>
          <div style="display: flex; justify-content: center; padding: 10px 0 20px 0">
            <div class="hand op account-btn" @click="delectSubToSection" style="background-color: #E4E6EB; margin-right: 30px">取消添加</div>
            <div class="hand op account-btn" @click="subToSection" style="background-color: #1479FF; color: #FFFFFF">确认添加</div>
          </div>
        </div>

      </el-dialog>

      <el-dialog title="管理部门" :visible.sync="showMangeSection" width="340px" center>
        <div style="border-bottom: 1px solid #CECFD0; margin: 10px 0 0 0"></div>
        <div style="height: 600px; overflow-y: scroll;">
          <div class="menu-title" style="font-size: 16px; margin-bottom: 4px; display: flex; justify-content: space-between" v-for="(item, index) in sectionList" :key="index">
            <div style="margin-left: 10px">
              <img style="width: 18px; height: 18px; display: inline-block; vertical-align: middle; margin: -3px 4px 0 0" src="../../../assets/imgs/circle/groupIcon.png">
              {{item.groupName}}
            </div>
            <div>
              <span class="hand op" style="margin-right: 20px" @click="toEditSection(item)">编辑</span>
              <span class="hand op" style="color: #a6a6a6" @click="toDeleteSection(item)">解散</span>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <!--开通会员信息-->
    <MessageVip @close="showVip = false" v-if="showVip === true" title="该机构账号还没有开通百工宝年费暂时无法创建机构子账号" content="（充值百工宝年费以创建子账号）"></MessageVip>
  </div>
</template>

<script>

import MessageVip from "./MessageVip";
export default {
  name: "SubsData",
  components: {MessageVip},
  data() {
    return {
      selectId: 0,

      // 子账号
      subTag: "1",
      showVip: false,
      showAddAccount: false,
      showNote: false,
      showAddSection: false,
      showSubs: false,
      showMangeSection: false,
      showEditSection: false,

      selectSection: {},   // 选中的部门
      selectSubList: [],  // 选中的子账号
      otherSubsList:[],//未被当前部门选择过的成员

      sectionInfo: {
        id: '',
        name: '',
        content: ''
      },

      sectionRules: {
        name: [
          { required: true, message: '部门名称不能为空', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请填写部门基本信息', trigger: 'blur' },
        ],
      },

      managerList: {
        admin: [],
        ordinary: []
      },

      // 验证码状态
      disabled: false,
      codeString: '获取验证码',
      time: 60,

      accountInfo: {
        isAdmin: 1,
        phone: '',
        account: '',
        pwd: '',
        code: '',
        remarks: ''
      },
      accountRules: {
        phone: [
          { required: true, message: '手机号码不能为空', trigger: 'blur' },
          { pattern: /^1[3|5|6|7|8|9][0-9]\d{8}$/, message: '请输入正确手机号码', trigger: 'blur'},
        ],

        pwd: [
          { required: true, message: '请输入子账号密码', trigger: 'blur' },
          { pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, message: '密码至少为8位字母和数字的组合', trigger: 'blur'},
        ],

        account: [
          { required: true, message: '子账号登录名不能为空', trigger: 'blur' },
          { pattern: /^\w{1,15}$/, message: '子账号名为长度不超过15位数字或字母的组合', trigger: 'blur'},
        ]
      },

      organAccountInfo: {},
      typeOptions: [
        {
          value: 1,
          label: '管理员'
        },
        {
          value: 0,
          label: '普通账号'
        }
      ],

      sectionList: [],
      subsList: []

    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  created: function () {

  },
  mounted: function () {
    //接收事件
    this.$EventBus.$on("getSubAccountList", () => {
      this.$nextTick(() => {
        this.getSubAccountList();
      });
    });
    this.getSubAccountList();
    this.getSectionList();
    this.getOrganUserInfo();
  },
  methods: {
    // 选择子账号
    selectSub(item) {
      if (JSON.stringify(item) !== '{}') {
        this.selectId = item.uuid
      } else {
        this.selectId = 0
      }
      this.$emit('selectSub', item)
    },

    // 获取机构账户登录信息
    getOrganUserInfo() {
      this.newApi.getMyAccount().then(res =>{
        this.organAccountInfo = res.data
      })
    },

    // 编辑部门
    toEditSection(item) {
      this.sectionInfo.id = item.id;
      this.sectionInfo.name = item.groupName;
      this.sectionInfo.content = item.content;
      this.showEditSection = true
    },

    // 确认编辑部门
    editSection() {
      var that = this;
      that.$refs.sectionForm.validate((valid) => {
        if (valid) {
          that.newApi.editOrgDepartment({groupId: that.sectionInfo.id, name: that.sectionInfo.name, content: that.sectionInfo.content}).then(res => {
            if (res.isSuccess == 1) {
              that.utils.sus(res.data);
              that.showEditSection = false;
              that.getSectionList()
            }
          })
        }
      })
    },

    // 删除部门
    toDeleteSection(item) {
      let that = this;
      this.utils.confirm('确认解散该部门？', function () {
        that.newApi.delOrgDepartment({groupId: item.id}).then(res => {
          if (res.isSuccess === 1) {
            that.utils.sus(res.data);
            that.getSectionList();
          }
        })
      })
    },

    // 取消添加到分组
    delectSubToSection() {
      this.showSubs = false;
      this.selectSubList = [];
      this.selectSection = {}
    },

    // 确认添加到分组
    subToSection() {
      this.newApi.addUserToDepartment({groupId: this.selectSection.id, uuid: this.selectSubList}).then(res => {
        if (res.isSuccess === 1) {
          this.utils.sus(res.data);
          this.showSubs = false;
          this.selectSubList = [];
          this.$EventBus.$emit("changeSubData")
        }
      })
    },

    // 选择部门
    selectSections(item) {
      //从之前的人里面除掉已经选择的人
      var that = this;
      this.selectSection = item;
      this.newApi.getDepartmentRemainderUserList({groupId: item.id}).then(res => {
        that.otherSubsList =res.data;
        that.showSubs = true
      })


    },

    // 获取部门列表
    getSectionList() {
      this.newApi.getOrgDepartmentList().then(res => {
        if (res.isSuccess == 1) {
          this.sectionList = res.data;
        }
      })
    },

    // 创建部门
    addSection() {
      var that = this;
      that.$refs.sectionForm.validate((valid) => {
        if (valid) {
          that.newApi.addOrgDepartment(that.sectionInfo).then(res => {
            if (res.isSuccess == 1) {
              that.utils.sus(res.data);
              that.showAddSection = false;
              that.sectionInfo = {
                id: '',
                name: '',
                content: ''
              };
              that.getSubAccountList();
              that.getSectionList()
            }
          })
        }
      })
    },

    // 获取短信验证码
    getCode() {
      var that = this;
      if(!(/^1[3456789]\d{9}$/.test(that.accountInfo.phone))){
        that.utils.err('您的手机号输入有误！');
        return;
      }
      that.changeCode();
      that.newApi.sendSms({mobile: that.accountInfo.phone, smsType: 1}).then(res =>{
        if (res.isSuccess == 1) {
          that.utils.sus(res.data);
        }
      })
    },

    changeCode(){
      var that = this;
      if(that.time == 0) {
        that.disabled = false;
        that.codeString ='获取验证码';
        that.time  = 60;
        return;
      } else {
        that.disabled = true;
        that.codeString = that.time + '秒后重新发送';
        that.time --;
      }
      setTimeout(function(){
        that.changeCode();
      }, 1000)

    },

    // 取消新增子账号
    cancelAccount() {
      this.showAddAccount = false;
      this.accountInfo = {
        isAdmin: 1,
        phone: '',
        account: '',
        pwd: '',
        code: '',
        remarks: ''
      }
    },

    // 新增子账号
    addAccount() {
      if (!this.user.subEndTime) {
        this.showVip = true
      } else {
        if (new Date(this.user.subEndTime).getTime()/ 1000 > Math.round(new Date().getTime()/1000)) {
          this.showAddAccount = true
        } else {
          this.showVip = true
        }
      }
    },

    // 获取子账号列表
    getSubAccountList() {
      this.newApi.getSubAccountList().then(res =>{
        this.managerList = res.data
        console.log(this.managerList)
        this.subsList = res.data.admin.concat(res.data.ordinary)
      })
    },

    // 获取子账号信息


    // 确定激活子账号
    DefiniteActivation() {
      const params = JSON.parse(JSON.stringify(this.accountInfo))
      params.account = this.organAccountInfo.accoun + "-" + this.accountInfo.account
      this.newApi.allocationAccount(params).then(res =>{
        if (res.isSuccess == 1) {
          this.accountInfo = {
            isAdmin: 1,
            phone: '',
            account: '',
            pwd: '',
            code: '',
            remarks: ''
          };
          this.showNote = false;
          this.showAddAccount = false;
          this.getSubAccountList();
          this.getSectionList()
          this.utils.sus(res.data)
        }
      })
    },

    // 激活子账号
    ActivateAccount() {
      this.$refs.accountForm.validate((valid) => {
        if (valid) {
          this.showNote = true
        }
      })
    },
  }
}
</script>

<style scoped>
.subs-info {
  width: 100%;
}

.subs-info .sub-btn {
  padding: 4px 14px;
  color: #666666;
  font-size: 16px;
  border: 1px solid #666666;
}

.account-btn {
  padding: 4px 20px;
  font-size: 16px;
  border-radius: 5px;
}

.form-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}

/deep/ .showNote .el-dialog__header {
  margin: 0 4% 0 0;
  padding: 12px 0 0 0;
  font-weight: 600;
}

/deep/ .showNote .el-dialog__body {
  padding: 0;
}

.menu-title {
  height: 42px;
  line-height: 40px;
  background-color: #F5F5F5;
  font-size: 16px;
  margin-bottom: 6px;
  padding: 0 10px;
}

.tags-child {
  width: 88%;
  height: 42px;
  padding: 0 10px;
  line-height: 38px;
  border-radius: 5px;
  margin: 2px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subsBox::-webkit-scrollbar {
  width: 0;
}

/deep/ .tabs .el-tabs__item {
  font-size: 16px;
}

/deep/ .tabs .el-tabs__nav {
  left: 10%;
}
</style>
